import validate from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/middleware/auth.global.ts";
import user_45status_45global from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/middleware/user-status.global.ts";
import tracking from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/core/dist/runtime/middleware/tracking.global.js";
import tracking_45service from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/core/dist/runtime/middleware/tracking-service.global.js";
import manifest_45route_45rule from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  user_45status_45global,
  tracking,
  tracking_45service,
  manifest_45route_45rule
]
export const namedMiddleware = {
  entrance: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/middleware/entrance.ts"),
  "recurring-consent": () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/middleware/recurring-consent.ts")
}