import { default as redirect_45assistantzxz8wDaxw5Meta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue?macro=true";
import { default as pending5oR3OVCEtwMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending.vue?macro=true";
import { default as affiliatexgY9mIa4GWMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/affiliate.vue?macro=true";
import { default as ansvarligt_45spilqXLsadruBPMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue?macro=true";
import { default as redirect_45assistantNpPNdeO8nMMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue?macro=true";
import { default as bankoWhJKd133SUMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/banko.vue?macro=true";
import { default as betingelserIR7rLwyguNMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/betingelser.vue?macro=true";
import { default as bingoi59APY43EfMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/bingo.vue?macro=true";
import { default as BlogUnUxi154cvMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/Blog.vue?macro=true";
import { default as bonuskodePfkp9GM8lIMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/bonuskode.vue?macro=true";
import { default as casinobIVN7YToxQMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/casino.vue?macro=true";
import { default as cookiepolitikeUlWNah4JXMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/cookiepolitik.vue?macro=true";
import { default as FAQpjJGjJ1uhIMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/FAQ.vue?macro=true";
import { default as _91slug_93h8lkr5W2kVMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue?macro=true";
import { default as indexkrl5dhW8TVMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/index.vue?macro=true";
import { default as kundeservice7wWt7lJaguMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/kundeservice.vue?macro=true";
import { default as om_45osYOh2eXze7RMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/om-os.vue?macro=true";
import { default as presseUHZq0TMksmMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/presse.vue?macro=true";
import { default as privatlivspolitikoH9NNKzCBRMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/privatlivspolitik.vue?macro=true";
import { default as signupz3vYJzeq4nMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/signup.vue?macro=true";
import { default as spilleautomaterrvwBVe42xIMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/spilleautomater.vue?macro=true";
import { default as spiloversigthpsNkscBSxMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/spiloversigt.vue?macro=true";
import { default as _91seoName_93G674Y3WCYXMeta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/spilside/[seoName].vue?macro=true";
import { default as tvda2xqSyzl3Meta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/tv.vue?macro=true";
import { default as vinderey7zQDVou74Meta } from "/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/vindere.vue?macro=true";
export default [
  {
    name: "redirect-assistant",
    path: "/payments/redirect-assistant",
    meta: redirect_45assistantzxz8wDaxw5Meta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue").then(m => m.default || m)
  },
  {
    name: "pending",
    path: "/payments/pending",
    meta: pending5oR3OVCEtwMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending.vue").then(m => m.default || m)
  },
  {
    name: "affiliate",
    path: "/affiliate",
    meta: affiliatexgY9mIa4GWMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: "ansvarligt-spil",
    path: "/ansvarligt-spil",
    meta: ansvarligt_45spilqXLsadruBPMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue").then(m => m.default || m)
  },
  {
    name: "app-redirect-assistant",
    path: "/app/redirect-assistant",
    meta: redirect_45assistantNpPNdeO8nMMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue").then(m => m.default || m)
  },
  {
    name: "banko",
    path: "/banko",
    meta: bankoWhJKd133SUMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/banko.vue").then(m => m.default || m)
  },
  {
    name: "betingelser",
    path: "/betingelser",
    meta: betingelserIR7rLwyguNMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/betingelser.vue").then(m => m.default || m)
  },
  {
    name: "bingo",
    path: "/bingo",
    meta: bingoi59APY43EfMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/bingo.vue").then(m => m.default || m)
  },
  {
    name: "Blog",
    path: "/Blog",
    meta: BlogUnUxi154cvMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/Blog.vue").then(m => m.default || m)
  },
  {
    name: "bonuskode",
    path: "/bonuskode",
    meta: bonuskodePfkp9GM8lIMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/bonuskode.vue").then(m => m.default || m)
  },
  {
    name: "casino",
    path: "/casino",
    meta: casinobIVN7YToxQMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/casino.vue").then(m => m.default || m)
  },
  {
    name: "cookiepolitik",
    path: "/cookiepolitik",
    meta: cookiepolitikeUlWNah4JXMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/cookiepolitik.vue").then(m => m.default || m)
  },
  {
    name: "FAQ",
    path: "/FAQ",
    meta: FAQpjJGjJ1uhIMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/FAQ.vue").then(m => m.default || m)
  },
  {
    name: "games-entrance-slug",
    path: "/games/entrance/:slug()",
    meta: _91slug_93h8lkr5W2kVMeta || {},
    alias: ["/spil/:slug","/spilleautomater/entrance/:slug"],
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexkrl5dhW8TVMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "kundeservice",
    path: "/kundeservice",
    meta: kundeservice7wWt7lJaguMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/kundeservice.vue").then(m => m.default || m)
  },
  {
    name: "om-os",
    path: "/om-os",
    meta: om_45osYOh2eXze7RMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/om-os.vue").then(m => m.default || m)
  },
  {
    name: "presse",
    path: "/presse",
    meta: presseUHZq0TMksmMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/presse.vue").then(m => m.default || m)
  },
  {
    name: "privatlivspolitik",
    path: "/privatlivspolitik",
    meta: privatlivspolitikoH9NNKzCBRMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/privatlivspolitik.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupz3vYJzeq4nMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "spilleautomater",
    path: "/spilleautomater",
    meta: spilleautomaterrvwBVe42xIMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/spilleautomater.vue").then(m => m.default || m)
  },
  {
    name: "spiloversigt",
    path: "/spiloversigt",
    meta: spiloversigthpsNkscBSxMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/spiloversigt.vue").then(m => m.default || m)
  },
  {
    name: "spilside-seoName",
    path: "/spilside/:seoName()",
    meta: _91seoName_93G674Y3WCYXMeta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/spilside/[seoName].vue").then(m => m.default || m)
  },
  {
    name: "tv",
    path: "/tv",
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/tv.vue").then(m => m.default || m)
  },
  {
    name: "vindere",
    path: "/vindere",
    meta: vinderey7zQDVou74Meta || {},
    component: () => import("/data/gitlab-runner/builds/sxU9ssJZ/0/spilnu/frontend/spilnu/pages/vindere.vue").then(m => m.default || m)
  }
]